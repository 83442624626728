import React, { useRef, useState, useEffect } from 'react';
import {
	CircularProgress, IconButton, Button,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Select, MenuItem, FormControl, InputLabel, ThemeProvider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip
} from '@mui/material';
import logger from '../../../utils/logger';
import {
	Close as CloseIcon,
	Edit as EditIcon,
	DeleteOutline as DeleteOutlineIcon,
	ArrowUpward as ArrowUpwardIcon,
	ArrowDownward as ArrowDownwardIcon,
	HorizontalSplitOutlined as HorizontalSplitOutlinedIcon,
	AccountTreeOutlined as AccountTreeOutlinedIcon,
	FileUploadOutlined as FileUploadOutlinedIcon,
	WarningAmber as WarningAmberIcon,
	FileDownloadOutlined as FileDownloadOutlinedIcon,
	Check as CheckIcon
} from '@mui/icons-material';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { GridCloseIcon } from '@mui/x-data-grid';

import ArticleVITSelector from '../../ArticleComponents/VITSelector';
import WYSIWYG from '../../ArticleComponents/WYSIWYG/WYSIWYG';
import ImageSelector from '../../ArticleComponents/ImageSelector';
import VideoSelector from '../../ArticleComponents/VideoSelector';
import TextInput from '../../ArticleComponents/TextInput';
import ArticleRenderer from '../ArticleRenderer/ArticleRenderer';

import { useAppSelector, useComponentRefs } from '../../../utils/hooks';
import { ArticleComponentType } from '../../../utils/constants/constants';
import { saveArticle, getArticle, updateArticle } from '../../../utils/api/article';
import { IArticle, IBody, ICurrentVersionResponse } from '../../../utils/interfaces/article';
import { lightTheme } from '../../../utils/theme/theme';

import { useDispatch } from 'react-redux';
import { defaultAppLanguage, setReduxStateLanguageEditor, setReduxStateLanguagePreview } from '../../../redux/slice/article.slice';

import styles from './AddEditArticle.module.scss';
import { getLangData } from '../../../utils/generic';
import { Language } from '../../../utils/types';
import CustomModal from '../../CustomModal';
import { formatDate } from 'date-fns';
import { ArticleStatus } from '../../../utils/constants/constants';

interface AddEditArticleProps {
	uuid?: string | null | undefined;
	isApprove?: boolean;
	onClose: () => void;
	onArticleChange: () => void;
	isDuplicate: boolean
}

interface SortArticleProps {
	updatedAt: string | number | Date;
}
const attributesList = ['subtitle', 'title', 'description', 'value', 'alt', 'icon', 'poster'];

let languages: Language[] = [];

const AddEditArticle: React.FC<AddEditArticleProps> = ({ uuid, isApprove, isDuplicate, onClose, onArticleChange }) => {
	const dispatch = useDispatch();

	const { selectedApplication } = useAppSelector((state) => state.application);
	const { selectedEdition } = useAppSelector((state) => state.edition);
	const [updateCount, setUpdateCount] = useState(0);

	// Language
	languages = selectedApplication.languages;
	const defaultLanguage = languages[0].code;
	const [languageEditor, setLanguageEditor] = useState(defaultLanguage);
	const [languagePreview, setLanguagePreview] = useState(defaultLanguage);
	useEffect(() => {
		dispatch(setReduxStateLanguageEditor(languageEditor));
	}, [languageEditor, dispatch]);
	useEffect(() => {
		dispatch(setReduxStateLanguagePreview(languagePreview));
	}, [languagePreview, dispatch]);

	// Sub Components
	const [selectedComponents, setSelectedComponents] = useState<{ component: JSX.Element | null, key: string }[]>([]);
	const { useAddComponentRef, useGetComponentRef } = useComponentRefs();
	const userPermissions = selectedApplication.permissions;
	const titleTextInputRef = useRef<any>(null);
	const subTitleTextInputRef = useRef<any>(null);
	const tagsTextInputRef = useRef<any>(null);
	const imageSelectorRef = useRef<any>(null);
	const vitSelectorRef = useRef<any>(null);

	const [titleTextInputData, setTitleTextInputData] = useState<{ value: string | null }>({ value: null });
	const [subTitleTextInputData, setSubTitleTextInputData] = useState<{ value: string | null }>({ value: null });
	const [tagsTextInputData, setTagsTextInputData] = useState<{ value: string | null }>({ value: null });
	const [imageSelectorData, setImageSelectorData] = useState<{ value: string | null }>({ value: null });

	const [loading, setLoading] = useState<boolean>(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [isSaveClicked, setIsSaveClicked] = useState(false);
	const [openChangeLogModal, setOpenChangeLogModal] = useState(false);
	const [changeLogs, setChangeLogs] = useState([]);

	const isMounted = useRef(false);

	const currentArticle = useRef<any>({});

	useEffect(() => {
		const fetchArticleData = async () => {
			if (uuid && !isMounted.current) {
				isMounted.current = true;
				setLoading(true);
				try {
					const article = await getArticle(uuid);
					if (article) {
						currentArticle.current = transformData(article.currentVersion, attributesList, languages);

						// add duplicate update title logic here
						isDuplicate && updateDuplicatedArticleTitle(currentArticle.current.title)

						setTitleTextInputData({ value: JSON.stringify(currentArticle.current.title) });
						setSubTitleTextInputData({ value: JSON.stringify(currentArticle.current.subtitle) });
						setImageSelectorData({ value: JSON.stringify(currentArticle.current.icon) });

						currentArticle.current.body.forEach((componentData: IBody, index: number) => {
							HandleAddComponent(componentData.type, componentData);
						});

						setTagsTextInputData({ value: currentArticle.current.catalogueTags.map((tag: string) => tag.trim()).join(', ') });
						const sortedPreviousVersions =
							article.previousVersions.length &&
							article.previousVersions.sort(
								(a: SortArticleProps, b: SortArticleProps) =>
									new Date(b.updatedAt).getTime() -
									new Date(a.updatedAt).getTime()
							);
						setChangeLogs(sortedPreviousVersions ?? []);

						setLoading(false);
					}
				} catch (error) {
					console.error('Error fetching article:', error);
				}
			}
		};
		fetchArticleData();
	}, [uuid, isDuplicate]);

	const updateDuplicatedArticleTitle = (data: { [x: string]: string; }) => {
		for (const languageCode in data) {
			data[languageCode] = data[languageCode] + ' (2)';
		}
	}

	const onComponentDataUpdate = () => {
		setUpdateCount(prev => prev + 1);
	};

	const handleEditorLanguageChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
		setLanguageEditor(event.target.value);
		dispatch(setReduxStateLanguageEditor(event.target.value.toString()));
	};

	const handlePreviewLanguageChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
		setLanguagePreview(event.target.value);
		dispatch(setReduxStateLanguagePreview(event.target.value.toString()));
	};

	const handleSave = async (selectedComponents: { component: JSX.Element | null, key: string }[], getComponentRef: (key: string) => React.MutableRefObject<any> | undefined, type: string) => {
		setIsSaveClicked(true);

		const componentData: any[] = [];
		selectedComponents.forEach(({ key }) => {
			const ref = getComponentRef(key);
			if (ref && ref.current) {
				const data = ref.current.getData();
				componentData.push(data);
			}
		});

		const imageData = imageSelectorRef.current?.getData().value || "https://icon.com/11233";
		const titleData = titleTextInputRef.current?.getData().value || "";
		const subTitleData = subTitleTextInputRef.current?.getData().value || "";
		const tagsData = getLangData(tagsTextInputRef.current?.getData().value) || "";

		const articleData: IArticle = {
			method: type,
			icon: imageData,
			title: titleData,
			subtitle: subTitleData,
			type: "article",
			description: "",
			metaTags: {
				rating: 0
			},
			catalogueTags: tagsData.split(',').map((tag: string) => tag.trim()),
			body: componentData
		};

		try {
			(uuid && !isDuplicate)
				? await updateArticle(articleData, uuid)
				: await saveArticle(articleData, selectedEdition.uuid);
			// Integrate global confirmation popup here. So keeping console for time being
			onArticleChange();
			onClose();
		} catch (error: any) {
			// Integrate global confirmation popup here. So keeping console for time being
			logger('error', error.message);
		}
	};

	const HandleAddComponent = (type: string, data?: any) => {
		const { key, ref } = useAddComponentRef();

		setSelectedComponents(prevSelectedComponents => {
			const component: JSX.Element | null = renderComponent(type, ref, data);
			return [...prevSelectedComponents, { component, key }];
		});
	};

	const handleSaveAsDraftWrapper = () => handleSave(selectedComponents, useGetComponentRef, ArticleStatus.Draft);
	const handleSaveAsPublishWrapper = () => handleSave(selectedComponents, useGetComponentRef, ArticleStatus.Pending);
	const handleSaveAsApproveWrapper = () => handleSave(selectedComponents, useGetComponentRef, ArticleStatus.Approved);

	function transformData(obj: any, attributesList: string[], languages: { code: string }[]) {
		attributesList.forEach(attribute => {
			if (obj.hasOwnProperty(attribute)) {
				const currentValue = obj[attribute];

				// If the value is not already an object (i.e., not localized), localize it
				if (typeof currentValue !== 'object' || currentValue === null) {
					try {
						obj[attribute] = JSON.parse(currentValue)
					} catch (error) {
						obj[attribute] = {};

						// Populate the languages with content or empty strings
						languages.forEach(language => {
							obj[attribute][language.code] = language.code === defaultAppLanguage ? currentValue : "";
						});
					}
				}
			} else if (Array.isArray(obj[attribute])) {
				// If the attribute is an array, recurse through its elements
				obj[attribute] = obj[attribute].map((item: any) => transformData(item, attributesList, languages));
			}
		});

		return obj;
	}

	const moveComponentUp = (key: string) => {
		const index = selectedComponents.findIndex(comp => comp.key === key);
		if (index > 0) {
			const updatedComponents = [...selectedComponents];
			[updatedComponents[index], updatedComponents[index - 1]] = [updatedComponents[index - 1], updatedComponents[index]];
			setSelectedComponents(updatedComponents);
		}
	};

	const moveComponentDown = (key: string) => {
		const index = selectedComponents.findIndex(comp => comp.key === key);
		if (index < selectedComponents.length - 1) {
			const updatedComponents = [...selectedComponents];
			[updatedComponents[index], updatedComponents[index + 1]] = [updatedComponents[index + 1], updatedComponents[index]];
			setSelectedComponents(updatedComponents);
		}
	};

	const removeComponent = (keyToRemove: string) => {
		const updatedComponents = selectedComponents.filter(({ key }) => key !== keyToRemove);
		setSelectedComponents(updatedComponents);
	};

	const renderComponent = (type: string, ref: React.MutableRefObject<any>, data: any): JSX.Element | null => {
		switch (type) {
			case ArticleComponentType.TEXT:
				return <WYSIWYG ref={ref} data={data} onChange={onComponentDataUpdate} />;
			case ArticleComponentType.VIDEO:
				return <VideoSelector ref={ref} data={data} onChange={onComponentDataUpdate} />;
			case ArticleComponentType.IMAGE:
				return <ImageSelector ref={ref} data={data} onChange={onComponentDataUpdate} isBody={true} />;
			default:
				return null;
		}
	};

	const handleCancel = () => {
		setConfirmationOpen(true);
	};

	const handleCancelConfirmed = () => {
		setConfirmationOpen(false);
		onClose();
	};

	const handleCancelCancelled = () => {
		setConfirmationOpen(false);
	};

	const handleOpenChangeLogModal = () => {
		setOpenChangeLogModal(true);
	};

	const handleCloseChangeLogModal = () => {
		setOpenChangeLogModal(false);
	};

	const getTimeZone = (date: string) => {
		const time = formatDate(date, "HH:mm").split("+")[0];
		const locale = new Intl.Locale(navigator.language);
		const zone = new Date(date)
			.toLocaleDateString(locale.baseName, {
				day: "2-digit",
				timeZoneName: "short",
			})
			.slice(4);
		return `${time} ${zone}`;
	};

	return (
		<ThemeProvider theme={lightTheme}>
			<div className={styles['add-edit-article-overlay']}>
				<div className={styles['add-edit-article-container']}>
					<div className={styles['title-bar']}>
						<div>
							<IconButton
								color="primary"
								size="small">
								<EditIcon />
							</IconButton>
							Article Editor
						</div>
						<IconButton
							color="primary"
							size="small"
							onClick={handleCancel}
						>
							<GridCloseIcon />
						</IconButton>
					</div>
					{!loading ?
						<>
							<div className={styles['article-content']}>
								<div className={styles['header-bar']}>
									<div className={styles['left-menu']}>
									{changeLogs.length > 0 && (
										<Button
											variant="text"
											color="primary"
											endIcon={<HorizontalSplitOutlinedIcon />}
											onClick={handleOpenChangeLogModal}
											disabled={isApprove}
										>
											Changelog
										</Button>
									)}
									</div>
									{/* For now this task will be on hold so commenting this code */}
									{/* <div className={styles['right-menu']}>
										{!isApprove && (
											<>
												<Button
													variant="outlined"
													color="primary"
													endIcon={<FileUploadOutlinedIcon />}
												>
													Import
												</Button>
												<Button
													variant="outlined"
													color="primary"
													endIcon={<FileDownloadOutlinedIcon />}
												>
													Export
												</Button>
											</>
										)}
									</div> */}
								</div>
								<div className={styles['add-edit-article-pane']}>
									<div className={styles['edit-column']}>
										<div className={styles['header']}>
											{languageEditor === defaultAppLanguage
												? ("Edit")
												: (<div className={styles['edit-warning']}><WarningAmberIcon />  Edit Translation Language</div>)
											}
											<FormControl variant="outlined" className={styles['form']}>
												<InputLabel id="language-select-label">Language</InputLabel>
												<Select
													labelId="language-select-label"
													value={languageEditor}
													onChange={handleEditorLanguageChange}
													label="Language"
													variant="outlined"
													size="small"
													disabled={isApprove}
												>
													{languages.map((lang) => (
														<MenuItem key={lang.code} value={lang.code}>
															{lang.language} ({lang.code.toUpperCase()})
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</div>
										<div className={styles['common-components']}>
											<TextInput
												ref={titleTextInputRef}
												data={titleTextInputData}
												label="Headline"
												onChange={onComponentDataUpdate}
												disabled={isApprove}
											/>
											<TextInput
												ref={subTitleTextInputRef}
												data={subTitleTextInputData}
												label="Subheading"
												onChange={onComponentDataUpdate}
												disabled={isApprove}
											/>
											<TextInput
												ref={tagsTextInputRef}
												data={tagsTextInputData}
												label="Tags"
												onChange={onComponentDataUpdate}
												unilingual={true}
												disabled={isApprove}
											/>
											<div className={styles['title-image-component']}>
												<ImageSelector
													ref={imageSelectorRef}
													data={imageSelectorData}
													onChange={onComponentDataUpdate}
													isBody={false}
													disabled={isApprove} />
											</div>
										</div>
										{
											(selectedComponents.length > 0) &&
											<div className={styles['selected-components']}>
												{selectedComponents.map(({ component, key }, index) => (
													<div key={key} className={languageEditor === defaultAppLanguage ? styles['helpers'] : ''}>
														{component}
														{languageEditor === defaultAppLanguage &&
															(<div className={styles['helper-buttons']}>
																{index > 0 &&
                                                                    <Button
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={() => moveComponentUp(key)}
                                                                        disabled={index === 0}>
                                                                        <ArrowUpwardIcon />
                                                                    </Button>
                                                                }
                                                                <Button
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => removeComponent(key)}>
                                                                    <DeleteOutlineIcon />
                                                                </Button>
                                                                {index === selectedComponents.length - 1 &&
																<Button
																	variant="outlined"
																	size="small"
																	onClick={() => moveComponentDown(key)}
																	disabled={index === selectedComponents.length - 1}>
																	<ArrowDownwardIcon />
																</Button>}
															</div>)}
													</div>
												))}
											</div>
										}

										{languageEditor === defaultAppLanguage &&
											(<div className={styles['article-vit-selector']}>
												<div className={styles['header']}>Add</div>
												<ArticleVITSelector ref={vitSelectorRef} onSelect={(type: string) => HandleAddComponent(type)} disabled={isApprove} />
											</div>)}
									</div>
									<div className={styles['preview-column']}>
										<div className={styles['header']}>
											Preview
											<FormControl variant="outlined" className={styles['form']}>
												<InputLabel id="language-select-label">Language</InputLabel>
												<Select
													labelId="language-select-label"
													value={languagePreview}
													onChange={handlePreviewLanguageChange}
													label="Language"
													variant="outlined"
													size="small"
												>
													{languages.map((lang) => (
														<MenuItem key={lang.code} value={lang.code}>
															{lang.language} ({lang.code.toUpperCase()})
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</div>
										<div className={styles['preview-pane']}>
											<ArticleRenderer
												updateCount={updateCount}
												components={selectedComponents}
												getComponentRef={useGetComponentRef}
												titleTextInputRef={titleTextInputRef}
												subTitleTextInputRef={subTitleTextInputRef}
												imageSelectorRef={imageSelectorRef}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className={styles['add-edit-article-action-buttons']}>
								<Button
									variant="outlined"
									onClick={handleCancel}
									startIcon={<CloseIcon />}
								>
									Discard
								</Button>
								<div>
									{(userPermissions?.includes("ArticleCollections.UpdateArticle") && !isApprove) && (
										<Button
											variant="contained"
											disabled={isSaveClicked}
											color="secondary"
											onClick={handleSaveAsDraftWrapper}
											endIcon={<SaveOutlinedIcon />}
										>
											Save as draft
										</Button>
									)}
									{(userPermissions?.includes("ArticleCollections.CreateArticle") && !isApprove) && (
										<Button
											variant="contained"
											disabled={isSaveClicked}
											color="primary"
											onClick={handleSaveAsPublishWrapper}
											endIcon={<TextSnippetOutlinedIcon />}
										>
											Request publishing
										</Button>
									)}
									{(userPermissions?.includes("ArticleCollections.ApproveArticle") && isApprove) && (
										<Button
											variant="contained"
											disabled={isSaveClicked}
											color="error"
											onClick={handleSaveAsDraftWrapper}
											endIcon={<ThumbDownOffAltIcon />}
										>
											Reject
										</Button>
									)}
									{(userPermissions?.includes("ArticleCollections.ApproveArticle") && isApprove) && (
										<Button
											variant="contained"
											disabled={isSaveClicked}
											color="success"
											onClick={handleSaveAsApproveWrapper}
											endIcon={<ThumbUpOffAltIcon />}
										>
											Approve
										</Button>
									)}
								</div>
							</div>
						</>
						: <div className={styles.customLoading}><CircularProgress /></div>}

					<Dialog open={confirmationOpen} onClose={handleCancelCancelled}>
						<DialogTitle>Confirm Cancel</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Are you sure you want to cancel? Updates will be lost.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCancelConfirmed} color="error" autoFocus>
								Yes, Cancel
							</Button>
							<Button onClick={handleCancelCancelled} color="primary">
								No
							</Button>
						</DialogActions>
					</Dialog>
					<CustomModal
						open={openChangeLogModal}
						onClose={handleCloseChangeLogModal}
						onSave={handleCloseChangeLogModal}
						title="Changelog"
						confirmText="OKAY"
						content=""
						ignoreCancelButton={true}
						saveIcon={<CheckIcon />}
						headerIcon={<HorizontalSplitOutlinedIcon />}
						hasBorder={true}
						hasPadding={false}
						isConfirmationButtonRight={true}
					>
						<>
							<div className={styles.tableLogContainer}>
								<p>This article was undergoing the following changes:</p>
								<TableContainer className={styles.tableContainer}>
									<Table>
										<TableHead>
											<TableRow className={styles.changeLogRow}>
												<TableCell>Latest modified</TableCell>
												<TableCell>Time</TableCell>
												<TableCell>User</TableCell>
												<TableCell>Status</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{changeLogs.length > 0 &&
												changeLogs.map(
													({
														uuid,
														updatedAt,
														user,
														status,
													}: ICurrentVersionResponse) => {
														return (
															<TableRow
																key={uuid}
																className={styles.changeLogRow}
															>
																<TableCell>
																	{formatDate(updatedAt, "dd-MMM-yyyy")}
																</TableCell>
																<TableCell>{getTimeZone(updatedAt)}</TableCell>
																<TableCell>
																	<Tooltip title={user && user} placement="top">
																		<span className={styles.userCell}>
																			{user && user}
																		</span>
																	</Tooltip>
																</TableCell>
																<TableCell className={styles.status}>
																	{status}
																</TableCell>
															</TableRow>
														);
													}
												)}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</>
					</CustomModal>
				</div>
			</div>
		</ThemeProvider>

	);
};

export default AddEditArticle;
