import React, {
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  SetStateAction,
} from "react";
import { useAppSelector } from "../../utils/hooks";
import {
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Checkbox,
  SelectChangeEvent,
  Select,
  OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  InputLabel,
  FormControl,
  CircularProgress,
  Autocomplete,
  Box,
  Divider,
  Chip,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  LocalOffer,
  ViewModule,
  FileUpload,
  Search,
  ViewList,
  Cancel,
} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import {
  bytesToMB,
  formatDate,
  convertSeconds,
  toTitleCase,
} from "../../utils/generic";
import {
  ASSET_SIZE_LIMIT,
  ASSET_UPLOAD_INDEX,
  SeverityType,
} from "../../utils/constants/constants";
import { IAssetItem } from "../../utils/interfaces/assets";
import AssetDataRenderer from "../../components/AssetComponents/AssetDataRenderer";
import styles from "./Assets.module.scss";
import customMuiLayer from "../../styles/customMuiLayer.module.scss";
import { AssetUploadModal } from "./AssetUploadModal";
import { AssetFilterModal } from "../../components/AssetComponents/AssetFilterModal";
import {
  fetchAssets,
  deleteSingleAsset,
  deleteMultipleAssets,
  updateAssetTags,
  duplicateAsset,
  fetchSuggestedTagsAPI,
} from "../../utils/api/assets";
import { handleFileProcessing } from "../../utils/helpers/assets/assetHelpers";
import { ASSET_FILTER_OPTIONS } from "../../utils/constants/constants";
import Snackbar from "../../components/Snackbar/Snackbar";
import CustomModal from "../../components/CustomModal";
import {
  DEFAULT_ITEMS_TO_LOAD,
  NEW_ITEMS_TO_LOAD,
} from "../../utils/constants/constants";

const filterOptions = ASSET_FILTER_OPTIONS;

const Assets: React.FC = () => {
  const { selectedApplication } = useAppSelector((state) => state.application);
  const userPermissions = selectedApplication.permissions;
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    severity: SeverityType.Success | SeverityType.Error;
  }>({
    open: false,
    message: "",
    severity: SeverityType.Success,
  });
  const [selectedFilters, setSelectedFilters] = useState<string[][]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [viewMode, setViewMode] = useState<"grid" | "list">("grid");
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedAssetsTags, setSelectedAssetsTags] = useState<
    { assetPublicId: string; tags: string }[]
  >([]);
  const [commonTags, setCommonTags] = useState<string[]>([]);
  const [selectedPublicId, setSelectedPublicId] = useState("");
  const [sampleAssets, setSampleAssets] = useState<IAssetItem[]>([]);
  const [deletionFailed, setDeletionFailed] = useState(false);
  const [sortOrderBy, setSortOrderBy] = useState<"date" | "title">("date");
  const [sortMode, setSortMode] = useState("desc");
  const [openAssetModal, setOpenAssetModal] = useState<boolean>(false);
  const [searchedAssets, setSearchedAssets] = useState<IAssetItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetData, setAssetData] = useState<any>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTagAssetsModal, setOpenTagAssetsModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedMultiDelete, setSelectedMultiDelete] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filterErrorMessage, setFilterErrorMessage] = useState("");
  const [filterValue, setFilterValue] = useState(null);
  const [filterTitle, setFilterTitle] = useState("");
  const [filterType, setFilterType] = useState("");
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [newTags, setNewTags] = useState<string[]>([]); 

  const [visibleAssetsCount, setVisibleAssetsCount] = useState(
    DEFAULT_ITEMS_TO_LOAD
  );

  const handleUploadAssetButtonClick = async (
    e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement> | any
  ) => {
    let files = e.target.files;
    if (files) {
      if (
        files.length === 1 &&
        Number(bytesToMB(files[0].size)) > ASSET_SIZE_LIMIT
      ) {
        hideSnackbar(
          false,
          `File Size > ${ASSET_SIZE_LIMIT}mb won't be uploaded`,
          SeverityType.Error
        );
      } else {
        const processedFiles = await handleFileProcessing(e.target.files);
        setAssetData((prevItems: any) => [...prevItems, ...processedFiles]);
        setOpenAssetModal(true);
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCloseAssetModal = () => {
    setAssetData([]);
    setOpenAssetModal(false);
  };

  const handleSortModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newSortMode: string | null
  ) => {
    if (newSortMode !== null) {
      setSortMode(newSortMode);
    }
  };

  const handleSortOrderByChange = (
    event?: SelectChangeEvent<"date" | "title">
  ) => {
    setSortOrderBy(event?.target.value as "date" | "title");
  };

  const changeView = (
    event: React.ChangeEvent<any>,
    viewMode: SetStateAction<"grid" | "list">
  ) => {
    if (viewMode !== null) {
      setViewMode(viewMode);
    }
  };

  const handleSort = () => {
    const sortedAssets = (
      searchQuery.length > 0 || selectedFilters.length > 0
        ? searchedAssets
        : sampleAssets
    ).sort((a, b) => {
      if (sortOrderBy === "date") {
        const dateA = new Date(a.lastModifiedDate).getTime();
        const dateB = new Date(b.lastModifiedDate).getTime();
        return sortMode === "asc" ? dateA - dateB : dateB - dateA;
      } else if (sortOrderBy === "title") {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) return sortMode === "asc" ? -1 : 1;
        if (titleA > titleB) return sortMode === "asc" ? 1 : -1;
        return 0;
      }
      return 0;
    });
    if (searchQuery || searchQuery !== "" || selectedFilters) {
      setSearchedAssets([...sortedAssets]);
      return;
    }
    setSearchedAssets([]);
    setSampleAssets([...sortedAssets]);
  };

  const handleFilterChange = (filterChange: any) => {
    setSelectedAssets([]);
    const filterOption = filterChange;
    if (filterOption.length < 2) return;
    if (filterOption[1].endsWith("...")) {
      setFilterTitle(filterOption[1].slice(0, -3));
      setFilterType(filterOption[0]);
      setOpenFilterModal(true);
    } else {
      const isAlreadyFiltered = selectedFilters.some((subOption) => {
        return subOption.every((value, index) => value === filterOption[index]);
      });
      if (!isAlreadyFiltered) {
        setSelectedFilters((prevFilters) => [...prevFilters, filterOption]);
      }
    }
  };

  const handleFilterRemove = (filterIndex: number) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((f, i) => i !== filterIndex)
    );
    setFilterType("");
    setFilterValue(null);
    setFilterTitle("");
    setFilterErrorMessage("");
  };

  const handleSelectAsset = (asset: any) => {
    setSelectedAssets((prevSelected) =>
      prevSelected.includes(asset.assetPublicId)
        ? prevSelected.filter((assetId) => assetId !== asset.assetPublicId)
        : [...prevSelected, asset.assetPublicId]
    );
    setSelectedAssetsTags((prevSelected) => {
      const assetExists = prevSelected.find(
        (item) => item.assetPublicId === asset.assetPublicId
      );
      // If the asset is already selected, remove it; otherwise, add the asset along with its tags
      if (assetExists) {
        return prevSelected.filter(
          (item) => item.assetPublicId !== asset.assetPublicId
        );
      } else {
        return [
          ...prevSelected,
          {
            assetPublicId: asset.assetPublicId,
            tags: asset.tags,
          },
        ];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedAssets(
      sampleAssets.length === selectedAssets.length
        ? []
        : sampleAssets.map((asset) => asset.assetPublicId)
    );
    setSelectedAssetsTags(
      sampleAssets.length === selectedAssets.length
        ? []
        : sampleAssets.map((asset) => ({
            assetPublicId: asset.assetPublicId,
            tags: asset.tags,
          }))
    );
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseTagAssetsModal = () => {
    setNewTags([])
    setInputValue("")
    setOpenTagAssetsModal(false);
    setSelectedTags(commonTags);
  };

  const tagAssetsHandler = async () => {
    try {
      if (!selectedAssets.length) {
        hideSnackbar(false, "Please select article", SeverityType.Error);
        return;
      }
      if (
        selectedTags.length === 0 ||
        (newTags.length === 0 && (commonTags.length === selectedTags.length &&
          commonTags
            .slice()
            .sort()
            .every(
              (value, index) => value === selectedTags.slice().sort()[index]
            )))) {
        hideSnackbar(false, "Please add tags", SeverityType.Error);
        return;
      }
      const res = await updateAssetTags(selectedApplication.uuid, {
        assetPublicIds: selectedAssets,
        newTags: [...selectedTags,...newTags]
      });
      if (res.status === 200) {
        await handleUpdateAssetList();
        setSelectedAssets([]);
        setSelectedAssetsTags([]);
        setOpenTagAssetsModal(false);
        setNewTags([]);
        hideSnackbar(
          false,
          "Asset Tags updated successfully",
          SeverityType.Success
        );
      } else {
        setOpenTagAssetsModal(false);
        hideSnackbar(
          false,
          "Asset Tags updation failed due to some issue",
          SeverityType.Error
        );
      }
    } catch (e) {
      hideSnackbar(
        false,
        "Asset Tags updation failed due to some issue",
        SeverityType.Error
      );
    }
  };

  const deleteSingle = async () => {
    try {
      setIsProcessing(true);
      const res = await deleteSingleAsset(
        selectedApplication.uuid,
        selectedPublicId
      );
      if (res.status === 200) {
        if (searchQuery.length > 0) {
          setSearchedAssets((prevAssets) =>
            prevAssets.filter(
              (asset) => asset.assetPublicId !== selectedPublicId
            )
          );
        }
        setSampleAssets((prevAssets) =>
          prevAssets.filter((asset) => asset.assetPublicId !== selectedPublicId)
        );
        setOpenDeleteModal(false);
        setDeletionFailed(false);
        setIsProcessing(false);
        hideSnackbar(false, "Asset deleted successfully", SeverityType.Success);
      } else {
        setIsProcessing(false);
        setDeletionFailed(true);
        setOpenDeleteModal(false);
        hideSnackbar(
          false,
          "Deletion failed due to some issue",
          SeverityType.Error
        );
      }
    } catch (e) {
      setIsProcessing(false);
      setDeletionFailed(true);
      hideSnackbar(
        false,
        `Deletion failed due to some issue`,
        SeverityType.Error
      );
    }
  };

  const confirmDeleteAsset = (uuid: string) => {
    setSelectedPublicId(uuid);
    setOpenDeleteModal(true);
  };
  const createDuplicate = (uuid: string) => {
    setIsLoading(true);
    duplicateAsset(uuid)
      .then((res) => {
        if (res.status === 200) {
          hideSnackbar(
            false,
            "Asset duplicated successfully",
            SeverityType.Success
          );
          handleUpdateAssetList();
          setIsLoading(false);
        } else {
          hideSnackbar(
            false,
            "Duplicating asset failed due to some issue",
            SeverityType.Error
          );
          setIsLoading(false);
        }
      })
      .catch((e) => {
        hideSnackbar(
          false,
          "Duplicating asset failed due to some issue",
          SeverityType.Error
        );
        setIsLoading(false);
      });
  };
  const deleteMultiple = async () => {
    try {
      setIsProcessing(true);
      if (!selectedAssets.length) {
        hideSnackbar(false, "Please select article", SeverityType.Error);
      }
      const res = await deleteMultipleAssets(selectedApplication.uuid, {
        assetPublicIds: selectedAssets,
      });
      if (res.status === 200 && res.data.acknowledged) {
        if (searchQuery.length > 0) {
          setSearchedAssets((prevAssets) =>
            prevAssets.filter(
              (asset) => !selectedAssets.includes(asset.assetPublicId)
            )
          );
        }
        setSampleAssets((prevAssets) =>
          prevAssets.filter(
            (asset) => !selectedAssets.includes(asset.assetPublicId)
          )
        );
        hideSnackbar(
          false,
          `${res.data.deletedCount} Assets deleted successfully`,
          SeverityType.Success
        );
        setDeletionFailed(false);
        setOpenDeleteModal(false);
        setSelectedMultiDelete(false);
        setIsProcessing(false);
        setSelectedAssets([])
      } else {
        setIsProcessing(false);
        hideSnackbar(
          false,
          `Deletion failed due to some issue`,
          SeverityType.Error
        );
        setDeletionFailed(true);
      }
    } catch (e) {
      setIsProcessing(false);
      hideSnackbar(
        false,
        `Deletion failed due to some issue`,
        SeverityType.Error
      );
      setDeletionFailed(true);
    }
  };

  const confirmDeleteMultipleAssets = () => {
    setSelectedMultiDelete(true);
    setOpenDeleteModal(true);
  };

  const tagAssets = () => {
    setOpenTagAssetsModal(true);
  };

  // Fetch suggested tags based on input value from backend
  const fetchSuggestedTags = async (query: string) => {
    if (query.trim() === "") {
      setSuggestedTags([]);
      return;
    }
    try {
      const tags = await fetchSuggestedTagsAPI(selectedApplication.uuid, query);
      const tagsToRemove = [...selectedTags, ...newTags];
      const filteredTags = tags.filter(tag => !tagsToRemove.includes(tag));
      setSuggestedTags(filteredTags);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  // Handle changes in selected tags
  const handleTagChange = (event: any, newValue: string[]) => {
    // Filter out old tags from the new values
    const addedNewTags = newValue.filter((tag) => !selectedTags.includes(tag));
    setNewTags(addedNewTags);
  };

  // Handle input change to trigger tag fetching from the backend
  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
    fetchSuggestedTags(newInputValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Check for Enter, comma, or space key presses
    if (event.key === "Enter" || event.key === "," || event.key === " ") {
      event.preventDefault(); // Prevent the default action of the key event
  
      const trimmedInputValue = inputValue.trim();
  
      // Check if the trimmed input is valid
      if (trimmedInputValue) {
        const normalizedTag = trimmedInputValue.toLowerCase(); // Normalize the input for comparison
  
        // Check if the tag is already in selectedTags
        if (!selectedTags.map(tag => tag.toLowerCase()).includes(normalizedTag)) {
          const updatedTags = [...selectedTags, normalizedTag];
          setNewTags(updatedTags);
          handleTagChange(event, updatedTags); 
          setInputValue(""); 
        } else {
          hideSnackbar(
            false,
            "Tag already exists",
            SeverityType.Error
          );
        }
      }
    }
  };


  const hideSnackbar = (
    hide: boolean = true,
    message: string = "",
    severity?: SeverityType
  ) => {
    setSnackbarState((prevState) => ({
      open: !hide,
      message: message,
      severity: severity || prevState.severity, 
    }));
  };

  const handleCloseSnackbar = () => {
    hideSnackbar();
  };

  useEffect(() => {
    (async () => {
      const assets = await fetchData();
      if (assets) {
        setSampleAssets(assets);
        return;
      }
    })();
  }, [selectedApplication.uuid]);

  useEffect(() => {
    (async () => {
      handleUpdateAssetList();
    })();
  }, [selectedFilters, searchQuery]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let filterObj: any = {};
      let filterValuesArr: any = [];
      selectedFilters &&
        selectedFilters.length > 0 &&
        selectedFilters.forEach((value) => {
          filterObj[`${value[0].trim()}`] = value[1].split(":")[1].trim();
          filterValuesArr.push({
            [value[0].trim()]: value[1].split(":")[1].trim(),
          });
        });
      const { assets }: any = await fetchAssets(selectedApplication.uuid, {
        searchTerms: [searchQuery],
        filterValues: filterValuesArr.length > 0 && filterValuesArr,
        sortOrderByValue: sortOrderBy === "date" ? "updatedAt" : "caption",
        sortMode,
      });

      return transformAssetData(assets);
    } catch (error) {
      console.error("Error fetching assets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0 && selectedFilters.length === 0) {
      setIsLoading(false);
      setSearchedAssets([]);
      return;
    }
    setIsLoading(true);
    let timeoutId = setTimeout(() => {
      handleSearchAsset();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchQuery, selectedFilters, filterValue]);

  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  };

  const handleSetFilterValues = (data: any) => {
    setFilterValue(data);
    setFilterErrorMessage("");
  };

  const handleSaveFilters = () => {
    if (!filterType || !filterValue) {
      setFilterErrorMessage("Please enter value...");
      return false;
    }
    const filterOption = [
      filterType,
      toTitleCase(`${filterTitle}`) + `: ${filterValue}`,
    ];
    const isAlreadyFiltered = selectedFilters.some((subOption) =>
      subOption.every((value, index) => value === filterOption[index])
    );
    if (!isAlreadyFiltered) {
      setSelectedFilters((prevFilters) => [...prevFilters, filterOption]);
    }
    setFilterType("");
    setFilterValue(null);
    setFilterTitle("");
    setFilterErrorMessage("");
    setOpenFilterModal(false);
  };

  const transformAssetData = (assets: any[]) => {
    if (!assets || assets.length === 0) return;
    let transformed: IAssetItem[] = assets.map((asset: any) => {
      const uploadIndex =
        asset?.eager?.thumbnail?.secure_url.indexOf("/upload/") +
        ASSET_UPLOAD_INDEX;
      const newSrc =
        asset?.eager?.thumbnail?.secure_url.slice(0, uploadIndex) +
        "a_" +
        asset?.rotate +
        "/" +
        asset?.eager?.thumbnail?.secure_url.slice(uploadIndex);
      return {
        id: asset.uuid,
        assetPublicId: asset.assetPublicId,
        thumbnail: newSrc,
        title: asset.caption,
        url: asset.externalResourceUrl,
        resolution: `${asset.width}x${asset.height}`,
        duration:
          asset.resource_type === "video"
            ? convertSeconds(asset.duration)
            : "-",
        tags: asset.tags.join(","),
        date: formatDate(asset.createdAt),
        type: asset.resource_type,
        fileSize: bytesToMB(asset.fileSizeInBytes) + "mb",
        lastModifiedDate: formatDate(asset.updatedAt),
        alt: asset.alt,
        percentCompleted: asset.percentCompleted,
        rotate: asset?.rotate,
        mirror: asset?.mirror,
      };
    });
    return transformed;
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const assetCount =
      searchQuery.length > 0 ? searchedAssets.length : sampleAssets.length;
    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      const count = (prevCount: number) =>
        Math.min(prevCount + NEW_ITEMS_TO_LOAD, assetCount);
      setVisibleAssetsCount(count);
    }
  };
  useEffect(() => {
    if (sampleAssets.length > 0 || searchedAssets.length > 0) {
      handleSort();
    }
  }, [sortMode, sortOrderBy]);

  useEffect(() => {
    if (selectedAssetsTags.length === 0) {
      setCommonTags([]);
      return;
    }
    // Split each asset's tags into arrays, and then find the common tags
    const tagsArray = selectedAssetsTags.map((asset) =>
      asset.tags && asset.tags.trim() !== ""
        ? asset.tags.split(",").map((tag) => tag.trim())
        : []
    );
    // Find common tags
    const common = tagsArray.reduce((acc, tags) => {
      return acc.filter((tag) => tags.includes(tag));
    }, tagsArray[0]);
    setCommonTags(common);
    setSelectedTags(common);
  }, [selectedAssetsTags]);

  const handleSearchAsset = async () => {
    if ((!searchQuery || searchQuery === "") && selectedFilters.length === 0) {
      setSearchedAssets([]);
      return;
    }

    let filteredAssets: IAssetItem[] = [];

    filteredAssets = (await fetchData()) as IAssetItem[];
    if (!filteredAssets || filteredAssets.length === 0) {
      setSearchedAssets([]);
      return;
    }

    setSearchedAssets(filteredAssets);
    setIsLoading(false);
  };

  const handleUpdateAssetList = async () => {
    const updatedAssets = await fetchData();
    if (updatedAssets) {
      if (searchQuery || selectedFilters.length) return;
      setSampleAssets(updatedAssets);
    }
  };

  const handleDisplayAssets = () => {
    const assetList =
      searchQuery.length > 0 || selectedFilters.length > 0
        ? searchedAssets.length > 0
          ? searchedAssets
          : []
        : sampleAssets && sampleAssets.length > 0
        ? sampleAssets
        : [];
    if (!assetList || assetList.length === 0) {
      return <div className={styles["no-data"]}>No Assets found</div>;
    }

    const assetCount =
      searchQuery || selectedFilters.length > 0
        ? searchedAssets.length
        : visibleAssetsCount;

    return [...assetList].slice(0, assetCount).map((asset) => {
      return (
        <AssetDataRenderer
          key={asset.id}
          asset={asset}
          viewMode={viewMode}
          isSelected={selectedAssets.includes(asset.assetPublicId)}
          onSelect={handleSelectAsset}
          onDelete={confirmDeleteAsset}
          onDuplicate={createDuplicate}
          handleUpdateAssetList={handleUpdateAssetList}
        />
      );
    });
  };

  return (
    <div className={styles["assets"]}>
      <AssetUploadModal
        selectedAssetData={assetData}
        openAssetModal={openAssetModal}
        handleCloseAssetModal={handleCloseAssetModal}
        handleUpdateAssetList={handleUpdateAssetList}
      />

      <div className={styles["header-bar"]}>
        <div className={styles["top-bar"]}>
          <div className={styles["title"]}>Asset Library</div>
          {userPermissions?.includes("ArticleCollections.CreateArticle") && (
            <Button
              variant="contained"
              component="label"
              color="primary"
              size="medium"
              tabIndex={-1}
            >
              Upload
              <FileUpload />
              <input
                ref={fileInputRef}
                className={styles.hiddenInput}
                type="file"
                onChange={(e) => handleUploadAssetButtonClick(e)}
                multiple={true}
                accept="image/*, video/*"
              />
            </Button>
          )}
        </div>

        <div className={styles["search-bar"]}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search term..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            label="Search"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <Select
            disabled={isLoading}
            size="small"
            value="--"
            onChange={(e) => handleFilterChange(e.target.value)}
            input={<OutlinedInput />}
            renderValue={() => "Add Filter"}
            style={{ marginLeft: "10px", width: "200px" }}
            className={customMuiLayer["MuiSelect-root"]}
          >
            <MenuItem value="--" disabled>
              Apply filter
            </MenuItem>
            {filterOptions.map((filter, i) => (
              <MenuItem key={i} value={[filter.key, filter.value]}>
                {filter.value.endsWith("...")
                  ? filter.value.slice(0, -3)
                  : filter.value}
              </MenuItem>
            ))}
          </Select>

          <div className={styles["filters"]}>
            {selectedFilters.map((filter, index) => (
              <Button
                key={index}
                variant="outlined"
                size="medium"
                endIcon={<Cancel />}
                onClick={() => handleFilterRemove(index)}
                style={{ margin: "5px" }}
              >
                {filter[1]}
              </Button>
            ))}
          </div>
        </div>

        <AssetFilterModal
          openFilterModal={openFilterModal}
          closeFilterModal={handleCloseFilterModal}
          filterTitle={filterTitle}
          filterErrorMessage={filterErrorMessage}
          filterType={filterType}
          filterValue={filterValue}
          handleSetFilterValues={handleSetFilterValues}
          handleSaveFilters={handleSaveFilters}
        />

        <div className={styles["view-bar"]}>
          <div className={styles["view-type"]}>
            <div>
              <span className={styles["view-text"]}>View:</span>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={changeView}
                size="small"
              >
                <ToggleButton value="grid">
                  <ViewModule />
                </ToggleButton>
                <ToggleButton value="list">
                  <ViewList />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <span className={styles["asset-count"]}>
              {searchQuery || selectedFilters.length > 0
                ? searchedAssets.length
                : sampleAssets.length}{" "}
              Assets
            </span>
          </div>
          <div className={styles["sort-select"]}>
            <FormControl variant="outlined">
              <InputLabel id="sort-label">Sort By</InputLabel>
              <Select
                disabled={isLoading}
                labelId="sort-label"
                value={sortOrderBy}
                onChange={handleSortOrderByChange}
                label="Sort By"
              >
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="title">Title</MenuItem>
              </Select>
            </FormControl>
            <div className={styles["sort-order"]}>
              Order:
              <ToggleButtonGroup
                disabled={isLoading}
                value={sortMode}
                size="small"
                exclusive
                onChange={handleSortModeChange}
                aria-label="sort mode"
              >
                <ToggleButton value="asc" aria-label="sort ascending">
                  <ArrowUpward />
                </ToggleButton>
                <ToggleButton value="desc" aria-label="sort descending">
                  <ArrowDownward />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["asset-view"]} onScroll={handleScroll}>
        {isLoading ? (
          <>
            <CircularProgress className={styles["loader"]} />
          </>
        ) : (
          ""
        )}
        {handleDisplayAssets()}
      </div>
      {selectedAssets.length > 0 && (
        <div className={styles["footer-bar"]}>
          <div>
            <div className={styles["select-group"]}>
              <Checkbox
                checked={
                  selectedAssets.length ===
                  (searchedAssets.length || sampleAssets.length)
                }
                onChange={handleSelectAll}
                color="primary"
              />
              <span>
                {selectedAssets.length ===
                (sampleAssets.length || searchedAssets.length) ? (
                  <span>Deselect all</span>
                ) : (
                  <span>Select all</span>
                )}
                <span className={styles["selected-items-count"]}>
                  {selectedAssets.length} of{" "}
                  {searchQuery ? searchedAssets.length : sampleAssets.length}{" "}
                  items selected
                </span>
              </span>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              size="medium"
              disabled={selectedAssets.length === 0}
              endIcon={<LocalOffer />}
              onClick={() => tagAssets()}
            >
              Tag
            </Button>
            <Button
              variant="contained"
              size="medium"
              endIcon={<Delete />}
              disabled={selectedAssets.length === 0}
              onClick={() => confirmDeleteMultipleAssets()}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      <CustomModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title={`Delete ${selectedMultiDelete ? "selected Assets" : "Asset"}`}
        content={`Are you sure that you want to delete ${
          selectedMultiDelete ? "the selected assets" : "the asset"
        } from the system?`}
        onSave={selectedMultiDelete ? deleteMultiple : deleteSingle}
        confirmText={deletionFailed ? "Try Again" : "Yes"}
        cancelText="No"
        isPositiveButton={false}
        isProcessing={isProcessing}
      />
      <CustomModal
        open={openTagAssetsModal}
        onClose={handleCloseTagAssetsModal}
        headerIcon={<SellOutlinedIcon />}
        saveIcon={<DoneIcon />}
        closeIcon={<CloseIcon />}
        title={`Tag ${selectedAssets.length > 1 ? "Files" : "File"}`}
        content=""
        onSave={tagAssetsHandler}
        confirmText={deletionFailed ? "Try Again" : "Confirm"}
        cancelText="Cancel"
        isPositiveButton={false}
        isProcessing={isProcessing}
      >
        <>
          <Box className={styles["tags-modal-box"]}>
            <Divider className={styles["tags-divider"]} />
            <Autocomplete
              multiple
              freeSolo
              options={suggestedTags}
              value={[...selectedTags, ...newTags]}
              onChange={handleTagChange}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={
                      selectedTags.includes(option)
                        ? undefined
                        : getTagProps({ index }).onDelete
                    } // Only allow deletion of new tags
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tags"
                  id="outlined-basic"
                  label="Common Tags"
                  size="medium"
                />
              )}
            />
            <Divider />
          </Box>
        </>
      </CustomModal>
      <Snackbar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default Assets;
