import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { IArticleComponentData } from '../../../utils/interfaces/article';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { defaultAppLanguage } from '../../../redux/slice/article.slice';
import { isValidJson } from '../../../utils/generic/string';

interface TextInputProps {
    data: {
        value: string | null;
    };
    onChange?: (data: IArticleComponentData) => void;
    label: string;
    unilingual?: boolean;
    disabled?: boolean;
}

export interface TextInputRef {
    getData: () => IArticleComponentData;
}

const TextInput: React.ForwardRefRenderFunction<TextInputRef, TextInputProps> = ({ data, label, onChange, unilingual, disabled }, ref) => {
    const [masterContent, setMasterContent] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');

    const language = useSelector((state: RootState) => state.article.languageEditor);
    const prevLanguageRef = useRef<string>(language);
    const dataRef = useRef<IArticleComponentData>({
        type: 'TEXT_INPUT',
        value: '',
        meta: {}
    });

    useEffect(() => {
        if (data && data.value) {
            setMasterContent(data.value);
        }
    }, [data]);

    useEffect(() => {
        updateInputContent(masterContent);
    }, [masterContent]);

    useEffect(() => {
        if (prevLanguageRef.current !== language) {
            updateInputContent(masterContent);
        }
    }, [language]);

    const getLanguage = (language: string): string => {
        return unilingual ? defaultAppLanguage : language
    }

    const updateInputContent = (content?: string) => {
        const contentToUse = content || masterContent;
        const languageCode = getLanguage(language)
        const parsedContent = isValidJson(contentToUse)
            ? JSON.parse(contentToUse)
            : { [languageCode]: contentToUse };

        setInputValue(parsedContent[languageCode] || '');
        prevLanguageRef.current = languageCode;

    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (inputValue === e.target.value) return;

        const languageCode = getLanguage(language)
        const currentMasterContent = isValidJson(masterContent) ? JSON.parse(masterContent) : { languageCode: masterContent };
        const updatedContent = {
            ...currentMasterContent,
            [languageCode]: e.target.value,
        };

        setMasterContent(JSON.stringify(updatedContent));
        setInputValue(e.target.value);

        if (onChange) {
            const latestData = getData();
            onChange(latestData);
        }

    };

    // Common Code
    const getData = (): IArticleComponentData => {
        return {
            ...dataRef.current,
            value: masterContent
        };
    };

    useImperativeHandle(ref, () => ({
        getData,
    }));

    return (
        <TextField
            label={label}
            variant="outlined"
            value={inputValue}
            onChange={handleChange}
            fullWidth
            disabled={disabled}
        />
    );
};

export default forwardRef(TextInput);
