import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from '../../../utils/theme/theme';
import styles from './ArticleRenderer.module.scss';
import { ArticleComponentType } from '../../../utils/constants/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getLangData } from '../../../utils/generic';

interface ArticleRendererProps {
    components: {
        component: JSX.Element | null;
        key: string;
    }[];
    getComponentRef: (key: string) => React.MutableRefObject<any> | undefined;
    imageSelectorRef: React.MutableRefObject<any>;
    titleTextInputRef: React.MutableRefObject<any>;
    subTitleTextInputRef: React.MutableRefObject<any>;
    updateCount: number;
}

const ArticleRenderer: React.FC<ArticleRendererProps> = ({
    components,
    getComponentRef,
    imageSelectorRef,
    titleTextInputRef,
    subTitleTextInputRef,
    updateCount }) => {
    const [renderedComponents, setRenderedComponents] = useState<JSX.Element[]>([]);
    const language = useSelector((state: RootState) => state.article.languagePreview);

    useEffect(() => {
        const updatedComponents: JSX.Element[] = components
            .map(({ key }) => {
                const ref = getComponentRef(key);
                if (ref && ref.current) {
                    const data = ref.current.getData();

                    switch (data.type) {
                        case ArticleComponentType.TEXT:
                            return (
                                <div
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: getLangData(data.value, language) || '' }}
                                />
                            );
                        case ArticleComponentType.VIDEO:
                            return (
                                <div key={key}>
                                    {data.value && (
                                        <video
                                            controls={data.attributes.controls}
                                            autoPlay={data.attributes.autoplay}
                                            loop={data.attributes.loop}
                                            muted={data.attributes.muted}
                                            poster={getLangData(data.value, language)?.attributes?.poster}
                                            src={getLangData(data.value, language)?.url}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            );
                        case ArticleComponentType.IMAGE:
                            return (
                                <div key={key}>
                                    {data.value && (
                                        <img
                                            src={getLangData(data.value, language)?.url}
                                            alt={getLangData(data.value, language)?.attributes?.alt || 'Image'}
                                        />)}
                                </div>
                            );
                        default:
                            return null;
                    }
                }
                return null;
            })
            .filter((element): element is JSX.Element => element !== null);

        setRenderedComponents(updatedComponents);
    }, [components, getComponentRef, updateCount, language]);

    return (
        <ThemeProvider theme={lightTheme}>
            <div className={styles['article-renderer']}>
                {
                    getLangData(titleTextInputRef?.current?.getData()?.value, language) && (
                        <div className={styles['title']}>
                            {getLangData(titleTextInputRef?.current?.getData()?.value, language)}
                        </div>
                    )
                }

                {
                    getLangData(subTitleTextInputRef?.current?.getData()?.value, language) && (
                        <div className={styles['sub-title']}>
                            {getLangData(subTitleTextInputRef?.current?.getData()?.value, language)}
                        </div>
                    )
                }

                {
                    getLangData(imageSelectorRef?.current?.getData()?.value, language)?.url && (
                        <div>
                            <img
                                src={getLangData(imageSelectorRef.current.getData().value, language)?.url}
                                alt={getLangData(imageSelectorRef.current.getData().value, language).attributes?.alt || 'Selected Image'}
                            />
                        </div>
                    )
                }

                {renderedComponents.length > 0 &&
                    <div className={styles['selected-components']}>
                        {renderedComponents}
                    </div>
                }
            </div>
        </ThemeProvider>
    );
};

export default ArticleRenderer;
