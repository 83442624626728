import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IArticleComponentData } from '../../../utils/interfaces/article';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import styles from './WYSIWYG.module.scss';
import { isValidJson } from '../../../utils/generic';

interface WYSIWYGProps {
    data: {
        value: string | null;
    };
    onChange?: (data: IArticleComponentData) => void;
}

export interface WYSIWYGRef {
    getData: () => IArticleComponentData;
}

const WYSIWYG: React.ForwardRefRenderFunction<WYSIWYGRef, WYSIWYGProps> = ({ data, onChange }, ref) => {
    const [masterContent, setMasterContent] = useState<string>('');
    const [inputValue, setInputValue] = useState<string>('');

    const language = useSelector((state: RootState) => state.article.languageEditor);
    const prevLanguageRef = useRef<string>(language);
    const dataRef = useRef<IArticleComponentData>({
        type: 'TEXT',
        value: '',
        meta: {}
    });

    useEffect(() => {
        if (data && data.value) {
            setMasterContent(data.value);
        }
    }, [data]);

    useEffect(() => {
        updateInputContent(masterContent);
    }, [masterContent]);

    useEffect(() => {
        if (prevLanguageRef.current !== language) {
            updateInputContent(masterContent);
        }
    }, [language]);

    const updateInputContent = (content?: string) => {
        const contentToUse = content || masterContent;
        const languageCode = language
        const parsedContent = isValidJson(contentToUse)
            ? JSON.parse(contentToUse)
            : { [languageCode]: contentToUse };

        setInputValue(parsedContent[languageCode] || '');
        prevLanguageRef.current = languageCode;

    };

    const handleChange = (content: string, delta: any, source: string, editor: any) => {
        if (inputValue === content) return;

        const contentToUse = content || masterContent;
        const languageCode = language
        const parsedContent = isValidJson(contentToUse)
            ? JSON.parse(contentToUse)
            : { [languageCode]: contentToUse };

        const updatedContent = {
            ...parsedContent,
            [language]: content,
        };

        setMasterContent(JSON.stringify(updatedContent));
        setInputValue(content);

        if (onChange) {
            const latestData = getData();
            onChange(latestData);
        }
    };

    // Common Code
    const getData = (): IArticleComponentData => {
        return {
            ...dataRef.current,
            value: masterContent
        };
    };

    useImperativeHandle(ref, () => ({
        getData,
    }));

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'clean', 'code-block']
        ]
    };

    const formats = [
        'header', 'font',
        'list', 'bullet', 'align',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background',
        'link', 'clean', 'code-block'
    ];

    return (
        <div>
            <ReactQuill
                theme="snow"
                value={inputValue}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                className={styles['quill-editor']}
            />
        </div>
    );
};

export default forwardRef(WYSIWYG);
