import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { Autocomplete, TextField, Switch } from '@mui/material';
import styles from './VideoSelector.module.scss';
import { IArticleComponentData, IAttributes } from '../../../utils/interfaces/article';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { defaultAppLanguage } from '../../../redux/slice/article.slice';
interface Video {
    name: string;
    url: string;
    attributes: IAttributes;
}
interface VideoSelectorProps {
    data?: {
        value: string | null;
        attributes: IAttributes;
    };
    onChange?: (data: IArticleComponentData) => void;
}

export interface VideoSelectorRef {
    getData: () => IArticleComponentData;
}

const VideoSelector: React.ForwardRefRenderFunction<VideoSelectorRef, VideoSelectorProps> = ({ data, onChange }, ref) => {
    const [masterContent, setMasterContent] = useState<Record<string, Video>>({});
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

    const language = useSelector((state: RootState) => state.article.languageEditor);
    const prevLanguageRef = useRef<string>(language);

    const [videos, setVideos] = useState<Video[]>([]);

    const [autoCompleteValue, setAutocompleteValue] = useState<Video | null>(null);

    useEffect(() => {
        const generateVideoData = () => {
            const sampleVideos: Video[] = [
                { name: 'Video 1', url: 'https://www.w3schools.com/html/mov_bbb.mp4', attributes: { alt: '', controls: false, autoplay: false, loop: false, muted: false, poster: '' } },
                { name: 'Video 2', url: 'https://www.w3schools.com/html/movie.mp4', attributes: { alt: '', controls: false, autoplay: false, loop: false, muted: false, poster: '' } },
                { name: 'Video 3', url: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4', attributes: { alt: '', controls: false, autoplay: false, loop: false, muted: false, poster: '' } }
            ];
            setVideos(sampleVideos);
        };

        generateVideoData();
    }, []);

    useEffect(() => {
        if (data && data.value) {
            const parsedData = JSON.parse(data.value);
            setMasterContent(parsedData);
        }
    }, [data]);

    useEffect(() => {
        const currentAttributes = masterContent[defaultAppLanguage]?.attributes
        const contentForLang = masterContent[language] || { url: '', attributes: currentAttributes || {}, name: '' };
        setSelectedVideo(contentForLang);
        prevLanguageRef.current = language;
    }, [language, masterContent]);

    useEffect(() => {
        if (onChange) {
            onChange(getData());
        }
    }, [selectedVideo, onChange]);

    const getData = (): IArticleComponentData => {
        return {
            type: 'VIDEO',
            value: JSON.stringify(masterContent),
            meta: {},
            attributes: selectedVideo?.attributes || {}
        };
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    const handleVideoSelect = (event: React.ChangeEvent<{}>, value: Video | null) => {
        if (value) {
            const currentAttributes = masterContent[defaultAppLanguage]?.attributes || {};

            const updatedVideo = {
                ...value,
                attributes: currentAttributes,
            };

            setMasterContent((prevData) => ({
                ...prevData,
                [language]: updatedVideo,
            }));

            setSelectedVideo(updatedVideo);
            setAutocompleteValue(null);
        }
    };

    const handleSelectedVideo = (key: string, value?: boolean | string) => {
        if (selectedVideo) {
            const updatedAttributes = {
                ...selectedVideo.attributes,
                [key]: value !== undefined ? value : '',
            };

            const updatedVideo = { ...selectedVideo, attributes: updatedAttributes };

            setMasterContent((prevData) => {
                const updatedPrevData = {
                    ...prevData,
                    [language]: updatedVideo,
                };

                const updatedMasterContent = Object.keys(updatedPrevData).reduce((acc, lang) => {
                    acc[lang] = {
                        ...updatedPrevData[lang],
                        attributes: updatedAttributes,
                    };
                    return acc;
                }, {} as Record<string, Video>);

                return updatedMasterContent;
            });

            setSelectedVideo(updatedVideo);
        }
    };

    return (
        <div className={styles['video-selector-container']}>
            <div className={styles['selected-video']}>
                {selectedVideo && (
                    <video controls src={selectedVideo.url} width="320" height="240">
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            <div className={styles['video-picker']}>
                <div>
                    <Autocomplete
                        options={videos}
                        getOptionLabel={(option) => option.name}
                        value={autoCompleteValue}
                        onChange={handleVideoSelect}
                        renderInput={(params) => (
                            <TextField {...params} label="Click inside to pick video" />
                        )}
                    />
                </div>
                <div>
                    <TextField
                        label="File name"
                        value={selectedVideo?.name || ''}
                        InputProps={{ readOnly: true }}
                    />
                </div>
                <div>
                    <TextField
                        label="URL"
                        value={selectedVideo?.url || ''}
                        InputProps={{ readOnly: true }}
                    />
                </div>
                {selectedVideo && (
                    <>
                        <div>
                            <label>Controls:</label>
                            <Switch
                                checked={selectedVideo.attributes.controls || false}
                                onChange={(e) => handleSelectedVideo('controls', e.target.checked)}
                                inputProps={{ 'aria-label': 'toggle button' }}
                            />
                        </div>
                        <div>
                            <label>Autoplay:</label>
                            <Switch
                                checked={selectedVideo.attributes.autoplay || false}
                                onChange={(e) => handleSelectedVideo('autoplay', e.target.checked)}
                                inputProps={{ 'aria-label': 'toggle button' }}
                            />
                        </div>
                        <div>
                            <label>Loop:</label>
                            <Switch
                                checked={selectedVideo.attributes.loop || false}
                                onChange={(e) => handleSelectedVideo('loop', e.target.checked)}
                                inputProps={{ 'aria-label': 'toggle button' }}
                            />
                        </div>
                        <div>
                            <label>Muted:</label>
                            <Switch
                                checked={selectedVideo.attributes.muted || false}
                                onChange={(e) => handleSelectedVideo('muted', e.target.checked)}
                                inputProps={{ 'aria-label': 'toggle button' }}
                            />
                        </div>
                        <div>
                            <label>Poster URL:</label>
                            <TextField
                                label="Poster URL:"
                                value={selectedVideo.attributes.poster || ''}
                                onChange={(e) => handleSelectedVideo('poster', e.target.value)}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default forwardRef(VideoSelector);
