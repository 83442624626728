import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import styles from './ImageSelector.module.scss';
import { IArticleComponentData, IAttributes } from '../../../utils/interfaces/article';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { defaultAppLanguage } from '../../../redux/slice/article.slice';

interface Image {
    name: string;
    url: string;
    attributes: IAttributes | { alt: string, loading: string };
}

interface ImageSelectorProps {
    data: {
        value: string | null;
        attributes?: IAttributes;
    };
    isBody?: boolean;
    onChange?: (data: IArticleComponentData) => void;
    disabled?: boolean;
}

export interface ImageSelectorRef {
    getData: () => IArticleComponentData;
}

const ImageSelector: React.ForwardRefRenderFunction<ImageSelectorRef, ImageSelectorProps> = ({ data, onChange, isBody, disabled }, ref) => {
    const [masterContent, setMasterContent] = useState<Record<string, Image>>({});
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);

    const language = useSelector((state: RootState) => state.article.languageEditor);
    const prevLanguageRef = useRef<string>(language);

    const [images, setImages] = useState<Image[]>([]);
    const [autoCompleteValue, setAutocompleteValue] = useState<Image | null>(null);

    useEffect(() => {
        const generateImageData = () => {
            const sampleImages: Image[] = [
                { name: 'Image 1', url: 'https://dummyimage.com/240x180/faa/333', attributes: { alt: '', loading: 'lazy' } },
                { name: 'Image 2', url: 'https://dummyimage.com/240x180/afa/666', attributes: { alt: '', loading: 'lazy' } },
                { name: 'Image 3', url: 'https://dummyimage.com/240x180/aaf/999', attributes: { alt: '', loading: 'lazy' } },
            ];
            setImages(sampleImages);
        };

        generateImageData();
    }, []);

    useEffect(() => {
        if (data && data.value) {
            const parsedData = JSON.parse(data.value);
            setMasterContent(parsedData);
        }
    }, [data]);

    useEffect(() => {
        const currentAttributes = masterContent[defaultAppLanguage]?.attributes;
        const contentForLang = masterContent[language] || { url: '', attributes: currentAttributes || {}, name: '' };
        setSelectedImage(contentForLang);
        prevLanguageRef.current = language;
    }, [language, masterContent]);

    useEffect(() => {
        if (onChange) {
            onChange(getData());
        }
    }, [selectedImage, onChange]);

    const getData = (): IArticleComponentData => {
        return {
            type: 'IMAGE',
            value: JSON.stringify(masterContent),
            meta: {},
            attributes: selectedImage?.attributes || {}
        };
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    const handleImageSelect = (event: React.ChangeEvent<{}>, value: Image | null) => {
        if (value) {
            const currentAttributes = masterContent[defaultAppLanguage]?.attributes || {};

            const updatedImage = {
                ...value,
                attributes: currentAttributes,
            };

            setMasterContent((prevData) => ({
                ...prevData,
                [language]: updatedImage,
            }));

            setSelectedImage(updatedImage);
            setAutocompleteValue(null);
        }
    };

    const handleSelectedImage = (key: string, value?: string) => {
        if (selectedImage) {
            const updatedAttributes = {
                ...selectedImage.attributes,
                [key]: value !== undefined ? value : '',
            };

            const updatedImage = { ...selectedImage, attributes: updatedAttributes };

            setMasterContent((prevData) => {
                const updatedPrevData = {
                    ...prevData,
                    [language]: updatedImage,
                };

                const updatedMasterContent = Object.keys(updatedPrevData).reduce((acc, lang) => {
                    acc[lang] = {
                        ...updatedPrevData[lang],
                        attributes: updatedAttributes,
                    };
                    return acc;
                }, {} as Record<string, Image>);

                return updatedMasterContent;
            });

            setSelectedImage(updatedImage);
        }
    };

    return (
        <div className={styles['image-selector-container']}>
            <div className={styles['selected-image']}>
                {selectedImage?.url && <img src={selectedImage.url} alt={selectedImage.attributes.alt || 'Selected image'} />}
            </div>
            <div className={styles['image-picker']}>
                <div>
                    <Autocomplete
                        options={images}
                        getOptionLabel={(option) => option.name}
                        value={autoCompleteValue}
                        onChange={handleImageSelect}
                        renderInput={(params) => (
                            <TextField {...params} label="Click inside to pick image" />
                        )}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <TextField
                        label="File name"
                        value={selectedImage?.name || ''}
                        InputProps={{ readOnly: true }}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <TextField
                        label="URL"
                        value={selectedImage?.url || ''}
                        InputProps={{ readOnly: true }}
                        disabled={disabled}
                    />
                </div>
                {isBody && (
                    <>
                        <div>
                            <TextField
                                label="Alt-Text"
                                value={selectedImage?.attributes.alt || ''}
                                onChange={(e) => handleSelectedImage('alt', e.target.value)}
                                disabled={disabled}
                            />
                        </div>
                        <div>
                            <TextField
                                label="Loading:"
                                value={selectedImage?.attributes.loading || ''}
                                onChange={(e) => handleSelectedImage('loading', e.target.value)}
                                disabled={disabled}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default forwardRef(ImageSelector);
